import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import Layout from '../components/Layout'
import PostList from '../components/PostList'

const Author = props => {
  const intl = useIntl()
  const { data } = props

  const { edges } = data.allWpUser
  const { posts: postNodes, name } = edges.length ? edges[0]?.node : {}
  const posts = postNodes.nodes
  const totalCount = (posts && posts.length) || 0

  let postSuffix = ''
  if (totalCount > 1 && totalCount <= 4) {
    postSuffix = 'y'
  }
  if (totalCount > 4) {
    postSuffix = 'ów'
  }
  const title =
    intl.locale === 'pl'
      ? `${totalCount} post${postSuffix} autorstwa ${name}`
      : `${totalCount} post${totalCount === 1 ? '' : 's'} by ${name}`

  // The `posts` returns a simple array instead of an array
  // of edges / nodes. We therefore need to convert the array here.
  let mappedPosts = []
  if (totalCount) {
    mappedPosts = posts.map(post => ({
      node: post,
    }))
  }

  return (
    <Layout>
      <Helmet title={`${name} | Post${postSuffix}`} />
      <PostList posts={mappedPosts} title={title} />
    </Layout>
  )
}

export default Author

export const pageQuery = graphql`
  query AuthorPage($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWpUser(filter: { id: { eq: $id } }) {
      edges {
        node {
          name
          posts {
            nodes {
              ...PostListFields
            }
          }
        }
      }
    }
  }
`
